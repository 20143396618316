import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import IframeResizer from 'iframe-resizer-react';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: auto;
`;

const IframeWraper = styled.div`
  width: 100%;
  margin: 0;
  border: none;
  overflow: hidden;

  .gameFrame {
    width: 100%;
    margin: auto;
    border: none;
    overflow: hidden;
    border-radius: 0.6rem;
  }
`;


const GamesPage = () => {
  const [height, setHeight] = useState(500);
  const [gameMode, setGameMode] = useState('');
  const [gameUrl, setURL] = useState('');

  useEffect(() => {
    const urlParams = new URL(window.location.href);
    setGameMode(urlParams.searchParams.get('game_id') ?? 'crash');
  }, []);

  useEffect(() => {
    const urlParams = new URL(window.location.href);
    let userId = urlParams.searchParams.get('user_id');
    let token = urlParams.searchParams.get('token');
    let currency = urlParams.searchParams.get('currency');
    const operator = urlParams.searchParams.get('operator') ?? 'ee2013ed-e1f0-4d6e-97d2-f36619e2eb52';
    const play_mode = urlParams.searchParams.get('play_mode');
    const lang = urlParams.searchParams.get('lang') ?? 'en';

    let operatorId: string = operator;
    switch (operator) {
      case 'pokerdom': operatorId = '087a693b-ede6-4f20-bd84-f2da223315c8';
    }

    if (play_mode && play_mode === 'demo') {
      operatorId = 'ee2013ed-e1f0-4d6e-97d2-f36619e2eb52';

      try {
        userId = localStorage.getItem('userId') ?? crypto.randomUUID();
        localStorage.setItem('userId', `${userId}`);
      } catch (e) {
        userId = crypto.randomUUID();
      }

      token = 'wdawd';
      currency = 'USD';
    }

    if (userId) {
      setURL(`https://games.inout.games/api/modes/${gameMode}/game?operator=${operatorId}&user_id=${userId}&auth_token=${token}&currency=${currency}&lang=${lang}`);
    } else {
      setURL(`https://games.inout.games/api/modes/${gameMode}/game?operator=${operatorId}&lang=${lang}`);
    }
    // setURL('https://games.cactusx.io/api/modes/crash/game?operator=fca2a331-fbf1-4dff-9f56-a8f91c68b4f9&user_id=46181&auth_token=slots__46181_e44229fe8f6465e0e846142dd15e84&currency=INR');
  }, [gameMode]);

  const messageHandler = useCallback((e: any) => {
    const { height } = e.data;
    if (height && height > 500) {
      setHeight(height);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', messageHandler, false);

    return () => window.removeEventListener('message', messageHandler, false);
  },[messageHandler]);

  return (
    <Wrapper>
      <IframeWraper>
        <IframeResizer
            log
            src={gameUrl}
            width="100%"
            height={height}
            id="myId"
            className="gameFrame"
        />
      </IframeWraper>
    </Wrapper>
  );
};

export default GamesPage;
